
    import {reactive,toRefs,onActivated,onMounted,getCurrentInstance,defineComponent} from 'vue';
    export default defineComponent({
        name: "MenuItem",
        setup(props:any,context:any){
            const {proxy}=getCurrentInstance() as any;
            const utils=proxy.utils;
            let dataObj=reactive({
                userMenus:context.attrs.userMenus
            })
            const toPage=(page:string,prop:any,type:any)=>{
                const userToken = sessionStorage.getItem(utils.Const.jfAccessToken);
                if(1==type){
                    window.open(page+'?token='+userToken);
                }else{
                    window.open(page);
                }
            }
            onMounted(()=>{})
            //如果通过浏览器的后退按钮可以退回到layer界面，然后在那里换一个菜单点击进来，这个时候不会调用onAmount方法，但是会调用onActivated方法
            //所以我们可以在这个方法里面改变userMenus，进而重新渲染
            onActivated(()=>{
                //MMP，锁屏再次解锁之后，这里会调用多次，会造成Slidebar里面描述的一样，每个一级菜单下面都会包含整个菜单，所以假如是否锁屏解锁的判断
                //为什么调用这么多次呢？因为MenuItem自己调用自己的时候也会调用onActivated，所以有多少个一级菜单就会调用几遍onActivated
                let lockScreen:ILockScreen=JSON.parse(sessionStorage.getItem("lockScreen") as string);
                if(lockScreen!=null && lockScreen.lockScreenState=='locked')return;
                if(sessionStorage.getItem("slideUserMenus")){
                    dataObj.userMenus = JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("slideUserMenus")));
                }else{
                    dataObj.userMenus = JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("userMenus")));
                }
            })

            return {
                ...toRefs(dataObj),toPage
            }
        },
        components: {}
    });
